import React from 'react';
import LogoTracking from '../ressources/logo-tracking.jpg';

function Header() {
  return (
    <div
      className="flex justify-center py-4 bg-white-100 dark:bg-gray-800 cursor-pointer"
      onClick={() => window.location = '/'}
    >
      <img src={LogoTracking} alt="Logo Tracking" className="h-auto" id="logo-header" />
    </div>
  );
}

export default Header;