// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: Poppins, sans-serif;
}

#logo-header {
    max-width: 200px;
}

.tracking-container div {
    display: flex;
    align-items: center;
    flex-direction: column;
    color: floralwhite;
}

.tracking-step {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: 0 0 20px 0;
    padding: 20px;
    border: 1px solid #e0e0e0;
    border-radius: 5px;
    background-color: #f9f9f9;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    margin: 15px;
    width: 20%;
}

.tracking-step p {
    margin: 0;
    color: #000;
}

footer {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100px;
    background-color: white;
    color: floralwhite;
    font-size: 1rem;
    width: 100%;
}

`, "",{"version":3,"sources":["webpack://./src/Pages/Tracking.css"],"names":[],"mappings":"AAEA;IACI,SAAS;IACT,UAAU;IACV,sBAAsB;IACtB,gCAAgC;AACpC;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,sBAAsB;IACtB,kBAAkB;AACtB;;AAEA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,sBAAsB;IACtB,kBAAkB;IAClB,aAAa;IACb,yBAAyB;IACzB,kBAAkB;IAClB,yBAAyB;IACzB,uCAAuC;IACvC,YAAY;IACZ,UAAU;AACd;;AAEA;IACI,SAAS;IACT,WAAW;AACf;;AAEA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,aAAa;IACb,uBAAuB;IACvB,kBAAkB;IAClB,eAAe;IACf,WAAW;AACf","sourcesContent":["@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');\n\n* {\n    margin: 0;\n    padding: 0;\n    box-sizing: border-box;\n    font-family: Poppins, sans-serif;\n}\n\n#logo-header {\n    max-width: 200px;\n}\n\n.tracking-container div {\n    display: flex;\n    align-items: center;\n    flex-direction: column;\n    color: floralwhite;\n}\n\n.tracking-step {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    flex-direction: column;\n    margin: 0 0 20px 0;\n    padding: 20px;\n    border: 1px solid #e0e0e0;\n    border-radius: 5px;\n    background-color: #f9f9f9;\n    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);\n    margin: 15px;\n    width: 20%;\n}\n\n.tracking-step p {\n    margin: 0;\n    color: #000;\n}\n\nfooter {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    height: 100px;\n    background-color: white;\n    color: floralwhite;\n    font-size: 1rem;\n    width: 100%;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
